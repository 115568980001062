.navbar {
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  background: #fff;
}
.navbar .navbar-brand a {
  color: #0d3e67;
}
.navbar a {
  text-decoration: none;
  cursor: pointer;
}
.navbar .navbar-nav a.active {
  border-bottom: solid 3px #0d3e67;
}
@media (max-width: 768px) {
  .navbar {
    min-height: 60px;
  }
  .navbar .navbar-nav a {
    color: #666;
    margin: none;
    padding: 10px 0 10px 0;
  }
  .navbar .navbar-nav a.active {
    background-color: #eee;
  }
  .navbar .navbar-nav a.active a:hover,
  .navbar .navbar-nav a.active a:focus {
    color: #000;
    background-color: #fff;
  }
  .navbar .navbar-header {
    margin-top: 10px;
  }
  .navbar .container {
    margin-left: 0;
    margin-right: 0;
  }
}
.navbar .navbar-nav a:hover,
.navbar .navbar-nav a:focus {
  color: #000;
  background: #fff;
  outline: none;
}
@media (min-width: 768px) {
  .navbar {
    padding: 15px 30px 5px;
  }
  .navbar .navbar-nav {
    margin-top: 15px;
    margin-bottom: -5px;
  }
  .navbar .navbar-nav a {
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
  }
  .navbar .navbar-nav .nav-item span:hover,
  .navbar .navbar-nav .nav-item span:focus {
    color: #000;
  }
  .navbar .navbar-nav .nav-item span {
    color: #333;
    font-size: 0.9rem;
  }
}
