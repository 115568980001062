.date-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px 0px inset;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  padding: 6px 12px;
  max-width: 11rem;
}

.calender-button {
  border: none;
  background-color: #ffffff;
  color: #888888;
  margin: 0 0 0 10px;
}
