/* credit to https://stackoverflow.com/questions/2812770/add-centered-text-to-the-middle-of-a-hr-like-line */
.DeletedPriceOptions__banner {
  display: flex;
  align-items: center;
  margin: 1em 0;
}

.DeletedPriceOptions__banner::before,
.DeletedPriceOptions__banner::after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
}
