html, body {
  font-size: 16px;
}

.pull-right {
  float: right !important;
}

.text-center {
  text-align: center;
}

.text-center-2 {
  text-align: center;
  margin: 20px 0 5px 0;
}
