.ValidationModal__dialog {
  max-width: 425px;
}

.fadeout::after {
  position: absolute;
  bottom: 15px;
  left: 0;
  height: 25px;
  width: 93%; /* prevents covering of the scroll bar */
  content: "";
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 10%, #ffffff 100%);
}
