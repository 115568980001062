a.ProgressBar__item:active,
a.ProgressBar__item:hover,
a.ProgressBar__item:focus {
  border-bottom: 3px solid #0d3e67;
  text-decoration: none;
}

a.ProgressBar__item {
  border-bottom: 3px solid transparent;
}

.ProgressBar__item--disabled {
  color: #6c757d;
}

.ProgressBar__item--active {
  border-bottom: 3px solid #0d3e67;
  color: #000;
}

.ProgressBar__statuslabel {
  font-size: 0.75em;
  font-weight: bold;
  margin-top: 5px;
  text-transform: uppercase;
  color: #f5a623;
}

div.ProgressBar__defaultcontainer {
  display: inline-block;
  margin-right: 2em;
  vertical-align: top;
}

@media (max-width: 1000px) {
  div.ProgressBar__defaultcontainer {
    display: block;
  }
}
